import React from 'react';

const Header = () => {
  return (
    <header>
      <div className='homeLinkContainer'>
        <a href='/'>StockOptionCalculator.com</a>
      </div>
      <div className='headerLinksContainer'>
        <div className='headerLink'>
          <a href='/#howToUse'>How to Use</a>
        </div>
        <div className='headerLink'>
          <a href='/#beginnersGuide'>Beginner's Guide</a>
        </div>
        <div className='headerLink'>
          <a href='https://www.buymeacoffee.com/stockoptioncalc'>Support Us</a>
        </div>
      </div>
    </header>
  );
};

export default Header;
