import React from 'react';

import { Typography, Box, Container, Paper } from '@material-ui/core';

import '../styles/about.css';
import '../App.css';

import { useStyles } from '../styles/aboutStyles';

const About = () => {
  const classes = useStyles();
  return (
    <Container maxWidth='sm' fixed id='about'>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Box textAlign='left' id='howToUse'>
            <Typography variant='h5'>
              HOW TO USE THIS CALCULATOR
            </Typography>
            <Typography variant='body2'>
              StockOptionCalculator.com is a free stock option
              calculator. This quick and simple tool allows beginners
              to easily calculate potential profits and returns on
              trading options based on a future estimated stock price.
            </Typography>
            <Box pt={2} fontWeight='bold'>
              <Typography variant='body1'>Steps:</Typography>
            </Box>
            <ol>
              <li>Select call or put option</li>
              <li>Enter the expiration date of the option</li>
              <li>Enter the strike price of the option</li>
              <li>
                Enter the amount of option contracts to be purchased
              </li>
              <li>Enter the price of the option</li>
              <li>Enter the current stock price</li>
              <li>
                Enter the stock price that you think the stock will be
                when the option expires
              </li>
            </ol>
          </Box>
        </Paper>
        <Paper className={classes.paper}>
          <Box textAlign='left' id='beginnersGuide'>
            <Typography variant='h5'>
              A Beginner’s Guide to Stock Options
            </Typography>
            <Box pb={2}>
              <Typography variant='body1'>
                What is a stock option?
              </Typography>
              <Typography variant='body2'>
                A stock option gives an investor the right to buy (or
                sell) stock at a specific price.
              </Typography>
            </Box>
            <Box pb={2}>
              <Typography variant='body1'>
                Why buy stock options instead of stocks (advantages of
                stock options)?
              </Typography>
              <Typography variant='body2'>
                Stock options allow investors to use leverage and
                multiply their potential return (and loss).
              </Typography>
            </Box>
            <Box pb={2}>
              <Typography variant='body1'>
                Why buy stocks instead of stock options (disadvantages
                of stock options)?
              </Typography>
              <Typography variant='body2'>
                Investors buying stock mainly consider whether the
                stock price will increase or decrease. Investors
                buying stock options have to consider not only whether
                the stock price will increase or decrease, but also at
                what magnitude and when. Getting any of these wrong
                could result in large losses, but getting all of these
                factors right could result in greater gains than
                buying just stock.
              </Typography>
            </Box>
            <Box pb={2}>
              <Typography variant='body1'>
                What are the different types of options for beginners?
              </Typography>
              <Typography variant='body2'>
                <li>
                  Call Option (C) - Gives an investor the right to buy
                  a stock at a specific price. Investors purchase call
                  options if they believe the stock is going to
                  increase.{' '}
                </li>
                <li>
                  Put Option (P) - Gives an investor the right to sell
                  a stock at a specific price. Investors purchase call
                  options if they believe the stock is going to
                  decrease.
                </li>
              </Typography>
            </Box>
            <Box pb={2}>
              <Typography variant='body1'>
                How to read options (stock option naming convention)
              </Typography>
              <Typography variant='body2'>
                Ticker Symbol + Expiration Year + Expiration Month +
                Expiration Day + Call or Put (C or P) + Strike Price
              </Typography>
            </Box>

            <Typography variant='h6'>Definitions</Typography>
            <Box pb={2}>
              <Typography variant='body2'>
                <b>Expiration Date - </b>The day when the stock option
                contract expires and is no longer valid after
              </Typography>
            </Box>
            <Box pb={2}>
              <Typography variant='body2'>
                <b>Strike Price - </b> The price that an investor can
                purchase (or sell) a stock regardless of what it is
                currently trading at
              </Typography>
            </Box>
            <Box pb={2}>
              <Typography variant='body2'>
                <b>Contract - </b> The right to buy (or sell) 100
                shares of the stock
              </Typography>
            </Box>
            <Box>
              <Typography variant='body2'>
                <b>Exercise - </b>To exercise a stock option is to
                choose to buy (or sell) the underlying shares at the
                strike price.{' '}
              </Typography>
            </Box>
            <Box p={1} fontStyle='italic'>
              <Typography variant='body2'>
                Example: If a stock is currently trading at $100, the owner of a $5 call option can choose to exercise the option and purchase 100 underlying shares for $5 for a profit of $95.
              </Typography>
            </Box>
          </Box>
        </Paper>
      </div>
    </Container>
  );
};

export default About;
