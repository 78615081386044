import React from 'react';

const Footer = () => {
  return (
    <footer>
      {' '}
      <small>
        &copy; Copyright {new Date().getFullYear()}{' '}
        StockOptionCalculator.com. All rights reserved. | <a href='/terms-and-conditions'>Terms and Conditions</a>
      </small>{' '}
    </footer>
  );
};

export default Footer;
