import React from 'react';
import { BrowserRouter as Router, 
  Route 
} from 'react-router-dom';

import { ThemeProvider } from '@material-ui/core/styles';

import { socTheme } from './styles/muiTheme';

import LandingPage from './components/LandingPage'

// import Calculator from './components/Calculator';
// import About from './components/About';
import Footer from './components/Footer';
import TermsAndConditions from './components/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy';
import Header from './components/Header'

import './App.css';

const App = (props) => {
  return (
    <Router>
      <ThemeProvider theme={socTheme}>
        <Header/>
        <Route exact path='/' component={LandingPage} />
        {/* <Route path='/about' component={About} /> */}
        <Route path='/terms-and-conditions' component={TermsAndConditions} />
        <Route path='/privacy-policy' component={PrivacyPolicy} />
        {/* <Calculator />
        <About /> */}
        <Footer />
      </ThemeProvider>
    </Router>
  );
};

export default App;
