import { makeStyles, withStyles } from '@material-ui/core/styles';

import { ToggleButtonGroup } from '@material-ui/lab';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(10),
      width: theme.spacing(60),
      padding: theme.spacing(3),
      '@media (max-width:600px)': {
        width: theme.spacing(40),
        margin: theme.spacing(5),
        padding: theme.spacing(1)
      },
    },
  },
  paper: {
    padding: theme.spacing(1),
    margin: theme.spacing(2),
  },
}));

export const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    // margin: theme.spacing(3, 'auto'),
    width: theme.spacing(25),
    margin: theme.spacing(2),
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
  selected: {
    backgroundColor: 'green',
  },
}))(ToggleButtonGroup);
