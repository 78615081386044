import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import {
  Container,
  Typography,
  Button,
  FormControl,
  Grid,
  TextField,
  Paper,
  InputAdornment,
  Box,
} from '@material-ui/core';

import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
// import AddIcon from '@material-ui/icons/Add';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { ToggleButton } from '@material-ui/lab';

import {
  useStyles,
  StyledToggleButtonGroup,
} from '../styles/formStyles';

import formSchema from '../validation/formSchema';

import '../App.css';

const initialCalculatorValues = {
  date: '',
  strikePrice: '',
  contracts: '',
  optionPrice: '',
  expirationPrice: '',
  currentPrice: '',
};

const initialFormErrors = {
  date: '',
  strikePrice: '',
  contracts: '',
  optionPrice: '',
  expirationPrice: '',
  currentPrice: '',
};

// const initialFieldErrors = {
//   date: false,
//   strikePrice: false,
//   contracts: false,
//   optionPrice: false,
//   expirationPrice: false,
//   currentPrice: false,
// };

const initialDisabled = true;

const Calculator = (props) => {
  const classes = useStyles();

  const [disabled, setDisabled] = useState(initialDisabled);
  const [formErrors, setFormErrors] = useState(initialFormErrors);
  // const [fieldErrors, setFieldErrors] = useState(initialFieldErrors);

  const [selectedDate, handleDateChange] = useState(new Date());

  const [calculation, setCalculation] = useState(() => 'call');

  const handleCalculation = (event, newCalculation) => {
    setCalculation(newCalculation);
    setFutureValue(null);
    setPercent(null);
    setDifference(null);
  };

  const [calculator, setCalculator] = useState(
    initialCalculatorValues,
  );
  const [futureValue, setFutureValue] = useState('');
  const [estimatedCost, setEstimatedCost] = useState('');
  const [difference, setDifference] = useState('');
  const [percent, setPercent] = useState('');
  const [curVsExpDiff, setCurVsExpDiff] = useState('');
  const [curVsExpPerc, setCurVsExpPerc] = useState('');

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    yup
      .reach(formSchema, name)
      .validate(value)
      .then((valid) => {
        setFormErrors({ ...formErrors, [name]: '' });
      })
      .catch((err) => {
        setFormErrors({
          ...formErrors,
          [name]: err.errors[0],
        });
      });

    setCalculator({
      ...calculator,
      [name]: value,
    });

    setFutureValue(null);
    setDifference(null);
    setPercent(null);
  };

  useEffect(() => {
    formSchema.isValid(calculator).then((valid) => {
      setDisabled(!valid);
    });
  }, [calculator]);

  useEffect(() => {
    if (calculator.contracts && calculator.optionPrice) {
      let estimatedCost =
        calculator.contracts * calculator.optionPrice * 100;

      setEstimatedCost(estimatedCost);
    } else {
      setEstimatedCost(null);
    }
  }, [calculator.contracts, calculator.optionPrice]);

  useEffect(() => {
    if (calculator.currentPrice && calculator.expirationPrice) {
      let curVsExpDiff =
        calculator.expirationPrice - calculator.currentPrice;
      setCurVsExpDiff(curVsExpDiff);

      let curVsExpPerc =
        calculator.expirationPrice / calculator.currentPrice - 1;
      setCurVsExpPerc(curVsExpPerc);
    } else {
      setCurVsExpDiff(null);
      setCurVsExpPerc(null);
    }
  }, [
    calculator.currentPrice,
    calculator.expirationPrice,
    estimatedCost,
    futureValue,
  ]);

  useEffect(() => {
    if (
      !calculator.expirationPrice ||
      !calculator.strikePrice ||
      !calculator.contracts
    ) {
      setFutureValue(null);
      setDifference(null);
      setPercent(null);
    }
  }, [
    calculator.strikePrice,
    calculator.expirationPrice,
    calculator.contracts,
  ]);

  const handleCalculate = (e) => {
    e.preventDefault();

    if (
      calculator.expirationPrice &&
      calculator.strikePrice &&
      calculator.contracts &&
      calculator.optionPrice
    ) {
      let futureValue =
        (calculator.expirationPrice - calculator.strikePrice) *
        calculator.contracts *
        100;

      if (calculation === 'call') {
        futureValue =
          (calculator.expirationPrice - calculator.strikePrice) *
          calculator.contracts *
          100;
      } else if (calculation === 'put') {
        futureValue =
          (calculator.strikePrice - calculator.expirationPrice) *
          calculator.contracts *
          100;
      }

      if (futureValue < 0) {
        futureValue = 0;
      }

      setFutureValue(futureValue);

      let difference = futureValue - estimatedCost;

      setDifference(difference);
      let percent = difference / estimatedCost;
      setPercent(percent);
    }
  };

  const displayDollars = (num) => {
    if (num > 0 && (num === difference || num === curVsExpDiff)) {
      return (
        '+' +
        Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(num)
      );
    } else {
      return Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(num);
    }
  };

  const displayDollarsAbs = (num) => {
    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(num);
  };

  const displayPercent = (num) => {
    if (num > 0) {
      return '+' + (num * 100).toFixed(2) + '%';
    } else {
      return (num * 100).toFixed(2) + '%';
    }
  };

  return (
    <Container maxWidth='md' fixed>
      <Grid
        container
        xs={12}
        direction='column'
        alignItems='center'
        justifyContent='center'
        justify='center'
        alignContent='center'
      >
        <div className={classes.root}>
          <Paper elevation={3}>
            <Typography variant='h1'>
              Stock Option Calculator
            </Typography>
            <form
              onSubmit={handleCalculate}
              disabled={disabled}
              errors={formErrors}
            >
              <FormControl fullWidth>
                <StyledToggleButtonGroup
                  value={calculation}
                  exclusive
                  onChange={handleCalculation}
                >
                  <ToggleButton variant='contained' value='call'>
                    Call
                  </ToggleButton>
                  <ToggleButton variant='contained' value='put'>
                    Put
                  </ToggleButton>
                </StyledToggleButtonGroup>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    variant='inline'
                    inputVariant='standard'
                    label='Expiration Date'
                    name='date'
                    format='MM/dd/yyyy'
                    value={selectedDate}
                    margin='normal'
                    onChange={(date) => {
                      handleDateChange(date);
                    }}
                  />
                </MuiPickersUtilsProvider>
                <TextField
                  variant='standard'
                  label='Strike Price'
                  name='strikePrice'
                  type='number'
                  margin='normal'
                  value={props.strikePrice}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        $
                      </InputAdornment>
                    ),
                    inputProps: {
                      min: 0.01,
                      step: 0.01,
                    },
                  }}
                  helperText={formErrors.strikePrice}
                  // error={fieldErrors.strikePrice}
                />
                <TextField
                  variant='standard'
                  label='Contracts (100 Shares)'
                  name='contracts'
                  type='number'
                  margin='normal'
                  value={props.contracts}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'></InputAdornment>
                    ),
                    inputProps: {
                      min: 1,
                    },
                  }}
                  helperText={formErrors.contracts}
                  // error={!disabled}
                />
                <TextField
                  variant='standard'
                  label='Option Price'
                  name='optionPrice'
                  type='number'
                  margin='normal'
                  value={props.optionPrice}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        $
                      </InputAdornment>
                    ),
                    inputProps: {
                      min: 0.01,
                      step: 0.01,
                    },
                  }}
                  helperText={formErrors.optionPrice}
                  // error={!disabled}
                />
                <Grid
                  container
                  xs={12}
                  // justify='space-between'
                  justifyContent='end'
                  // alignItems='center'
                  // wrap='nowrap'
                >
                  <Grid
                    item
                    xs={6}
                    zeroMinWidth
                    alignContent='flex-end'
                  >
                    <Typography variant='body1'>
                      Total Cost:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} zeroMinWidth>
                    {estimatedCost != null &&
                    calculator.strikePrice >= 0 &&
                    calculator.optionPrice >= 0 &&
                    calculator.contracts >= 0 ? (
                      <Typography variant='body1' align='right'>
                        {displayDollars(estimatedCost)}
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
                <TextField
                  variant='standard'
                  label='Current Stock Price'
                  name='currentPrice'
                  type='number'
                  margin='normal'
                  value={props.currentPrice}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        $
                      </InputAdornment>
                    ),
                    inputProps: {
                      min: 0.01,
                      step: 0.01,
                    },
                  }}
                  helperText={formErrors.currentPrice}
                  // error={!disabled}
                />
                <TextField
                  variant='standard'
                  label='Estimated Stock Price at Expiration'
                  name='expirationPrice'
                  type='number'
                  margin='normal'
                  value={props.expirationPrice}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        $
                      </InputAdornment>
                    ),
                    inputProps: {
                      min: 0.01,
                      step: 0.01,
                    },
                  }}
                  helperText={formErrors.expirationPrice}
                  // error={!disabled}
                />
                <Grid
                  container
                  xs={12}
                  direction='row'
                  justify='space-between'
                >
                  <Grid item xs={8} sm={6} zeroMinWidth>
                    <Typography variant='body1'>
                      Expected Change:
                    </Typography>
                  </Grid>
                  {curVsExpDiff != null &&
                  calculator.currentPrice >= 0 &&
                  calculator.expirationPrice >= 0 &&
                  curVsExpPerc != null ? (
                    <Grid item xs={4} sm={6} zeroMinWidth>
                      {/* <Grid container xs={12}>
                        <Grid item xs={6} zeroMinWidth> */}
                      <Typography variant='body1' align='right'>
                          { curVsExpDiff > 0 && curVsExpPerc > 0 ? (
                          <TrendingUpIcon
                            color='primary'
                            fontSize='inherit'
                          />
                        ) : (
                          <TrendingDownIcon
                            color='error'
                            fontSize='inherit'
                          />
                        )}{' '}
                          { displayDollars( curVsExpDiff ) }{ ' ' }({ displayPercent( curVsExpPerc ) })
                        {/* </Typography>
                        </Grid>
                        <Grid item xs={6} zeroMinWidth>
                          <Typography variant='body1' align='right'> */}
                          {' '}
                        {/* (
                        {curVsExpPerc > 0 ? (
                          <TrendingUpIcon
                            color='primary'
                            fontSize='inherit'
                          />
                        ) : (
                          <TrendingDownIcon
                            color='error'
                            fontSize='inherit'
                          />
                        )}{' '}
                        {displayPercent(curVsExpPerc)}) */}
                      </Typography>
                    </Grid>
                  ) : //   </Grid>
                  // </Grid>
                  null}
                </Grid>
                <Button
                  variant='contained'
                  color='primary'
                  type='submit'
                  onClick={handleCalculate}
                  // disabled={disabled}
                >
                  Calculate
                </Button>
                {difference != null &&
                percent != null &&
                calculator.strikePrice >= 0 &&
                calculator.contracts >= 0 &&
                calculator.optionPrice >= 0 &&
                calculator.currentPrice >= 0 &&
                calculator.expirationPrice >= 0 ? (
                  <Grid container xs={12} justify='space-between'>
                    <Grid item xs={6} sm={7} zeroMinWidth>
                      <Typography variant='body1'>
                        Total Return on Option:
                      </Typography>{' '}
                    </Grid>
                    <Grid item xs={6} sm={5} zeroMinWidth>
                      {/* <Grid container xs={12} direction='column'>
                        <Grid item xs={12} zeroMinWidth> */}
                          <Typography variant='body1' align='right'>
                            {difference > 0 && percent > 0 ? (
                              <TrendingUpIcon
                                color='primary'
                                fontSize='inherit'
                              />
                            ) : (
                              <TrendingDownIcon
                                color='error'
                                fontSize='inherit'
                              />
                            )}
                              { ' ' }{ displayDollars( difference ) }{ ' ' }({ displayPercent( percent ) })
                          {/* </Typography>
                        </Grid>
                        <Grid item xs={12} zeroMinWidth>
                          <Typography variant='body1' align='right'>
                            {' '}
                            (
                            {difference > 0 && percent > 0 ? (
                              <TrendingUpIcon
                                color='primary'
                                fontSize='inherit'
                              />
                            ) : (
                              <TrendingDownIcon
                                color='error'
                                fontSize='inherit'
                              />
                            )}{' '}
                            {displayPercent(percent)}) */}
                          </Typography>
                        </Grid>
                      </Grid>
                  //   </Grid>
                  // </Grid>
                ) : null}
                <Grid container item xs={12} zeroMinWidth>
                  <Box pl={1.3} pt={2}>
                    {futureValue != null &&
                    calculator.strikePrice >= 0 &&
                    calculator.contracts >= 0 &&
                    calculator.optionPrice >= 0 &&
                    calculator.currentPrice >= 0 &&
                    calculator.expirationPrice >= 0 ? (
                      <Typography variant='body2'>
                        At expiration, your options could be worth{' '}
                        {displayDollars(futureValue)}
                        {difference !== 0 ? (
                          <span>
                            {' '}
                            for a total{' '}
                            {difference > 0 ? `gain` : `loss`} of{' '}
                            {displayDollarsAbs(Math.abs(difference))}.
                          </span>
                        ) : (
                          ` with no gain or loss on your initial investment.`
                        )}
                      </Typography>
                    ) : null}
                  </Box>
                </Grid>
              </FormControl>
            </form>
          </Paper>
        </div>
      </Grid>
    </Container>
  );
};

export default Calculator;
