import * as yup from 'yup';

const formSchema = yup.object().shape({
  strikePrice: yup
    .number('Please input a number value.')
    .min(0, 'Please input a number greater than or equal to 0.')
    .required('This is a required field.')
    .typeError('This is a required field.'),
  contracts: yup
    .number('Please input a number value.')
    .min(0, 'Please input a number greater than or equal to 0.')
    .required('This is a required field.')
    .typeError('This is a required field.'),
  optionPrice: yup
    .number('Please input a number value.')
    .min(0, 'Please input a number greater than or equal to 0.')
    .required('This is a required field.')
    .typeError('This is a required field.'),
  currentPrice: yup
    .number('Please input a number value.')
    .min(0, 'Please input a number greater than or equal to 0.')
    .required('This is a required field.')
    .typeError('This is a required field.'),
  expirationPrice: yup
    .number('Please input a number value.')
    .min(0, 'Please input a number greater than or equal to 0.')
    .required('This is a required field.')
    .typeError('This is a required field.'),
});

export default formSchema;
