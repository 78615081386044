import React from 'react';

import Calculator from './Calculator';
import About from './About';

const LandingPage = () => {
  return (
    <>
      <Calculator />
      <About />
    </>
  );
};

export default LandingPage;
