import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(2),
      // width: theme.spacing(60),
      padding: theme.spacing(3),
      '@media (max-width:600px)': {
        // width: theme.spacing(40),
        margin: theme.spacing(2),
      },
    },
  },
  paper: {
    padding: theme.spacing(5),
    margin: theme.spacing(2),
    '@media (max-width: 600px)': {
      padding: theme.spacing(3),
    }
  
  },
}));
