import React from 'react';

import { Typography, Box, Container } from '@material-ui/core';

const PrivacyPolicy = () => {
  return (
    <Container>
      <Box px={5} py={2}>
        <Typography variant='h5'>
          Privacy Policy - "Stock Option Calculator"
        </Typography>
      </Box>
      <Box px={5} py={1}>
        <Typography variant='body2'>
          “Stock Option Calculator” app is a Freemium app. This app is
          provided at no cost and is intended for use as is.
        </Typography>
      </Box>
      <Box px={5} py={1}>
        <Typography variant='body2'>
          This page is used to inform visitors regarding my policies
          with the collection, use, and disclosure of Personal
          Information if anyone decided to use this app.
        </Typography>
      </Box>
      <Box px={5} py={1}>
        <Typography variant='body2'>
          If you choose to use this app, then you agree to the
          collection and use of information in relation to this
          policy. The Personal Information that I collect is used for
          providing and improving your experience in the app. I will
          not use or share your information with anyone except as
          described in this Privacy Policy.
        </Typography>
      </Box>
      <Box px={5} py={1}>
        <Typography variant='body2'>
          None of your personal information is or will be sold. Any
          information collected by this app is solely used to help
          understand who its users are and how the app is used in
          order to provide future updates with enhanced user
          experiences.
        </Typography>
      </Box>
      <Box px={5} py={1}>
        <Typography variant='body2'>
          The terms used in this Privacy Policy have the same meanings
          as in our Terms and Conditions unless otherwise defined in
          this Privacy Policy.
        </Typography>
      </Box>
      <Box px={5} py={1}>
        <Typography variant='h6'>
          Information Collection and Use
        </Typography>
      </Box>
      <Box px={5} py={1}>
        <Typography variant='body2'>
          The app uses third party services that may collect
          information used to identify you. Links to privacy policy of
          third party service providers used by the app:
          <li>Google Analytics for Firebase</li>
          <li>Firebase Crashlytics</li>
        </Typography>
      </Box>
      <Box px={5} py={1}>
        <Typography variant='h6'>Log Data</Typography>
      </Box>
      <Box px={5} py={1}>
        <Typography variant='body2'>
          I want to inform you that whenever you use this app, in the
          case of an error/crash in the app, I collect data and
          information (through third party products) on your phone
          called Log Data. This Log Data may include information such
          as your device Internet Protocol (“IP”) address, device
          name, operating system version, the configuration of the app
          when utilizing my Service, the time and date of your use of
          the Service, and other statistics.
        </Typography>
      </Box>
      <Box px={5} py={1}>
        <Typography variant='h6'>Cookies</Typography>
      </Box>
      <Box px={5} py={1}>
        <Typography variant='body2'>
          Cookies are files with a small amount of data that are
          commonly used as anonymous unique identifiers. These are
          sent to your browser from the websites that you visit and
          are stored on your device's internal memory. This Service
          does not use these “cookies” explicitly. However, the app
          may use third party code and libraries that use “cookies” to
          collect information and improve their services. You have the
          option to either accept or refuse these cookies and know
          when a cookie is being sent to your device. If you choose to
          refuse our cookies, you may not be able to use some portions
          of this Service.
        </Typography>
      </Box>
      <Box px={5} py={1}>
        <Typography variant='h6'>Service Providers</Typography>
      </Box>
      <Box px={5} py={1}>
        <Typography variant='body2'>
          I may employ third-party companies and individuals due to
          the following reasons:
          <li>To facilitate our Service; </li>
          <li> To provide the Service on our behalf; </li>
          <li>To perform Service-related services; or</li>
          <li>To assist us in analyzing how our Service is used.</li>
        </Typography>
      </Box>
      <Box px={5} py={1}>
        <Typography variant='body2'>
          I want to inform users of this app that these third parties
          may have access to your Personal Information. The reason is
          to perform the tasks assigned to them on our behalf.
          However, they are obligated not to disclose or use the
          information for any other purpose. Security I value your
          trust in providing us your Personal Information, thus we are
          striving to use commercially acceptable means of protecting
          it. But remember that no method of transmission over the
          internet, or method of electronic storage is 100% secure and
          reliable, and I cannot guarantee its absolute security.
        </Typography>
      </Box>
      <Box px={5} py={1}>
        <Typography variant='h6'>Links to Other Sites</Typography>
      </Box>
      <Box px={5} py={1}>
        <Typography variant='body2'>
          This Service may contain links to other sites. If you click
          on a third-party link, you will be directed to that site.
          Note that these external sites are not operated by me.
          Therefore, I strongly advise you to review the Privacy
          Policy of these websites. I have no control over and assume
          no responsibility for the content, privacy policies, or
          practices of any third-party sites or services.
        </Typography>
      </Box>
      <Box px={5} py={1}>
        <Typography variant='h6'>Children’s Privacy</Typography>
      </Box>
      <Box px={5} py={1}>
        <Typography variant='body2'>
          These Services do not address anyone under the age of 13. I
          do not knowingly collect personally identifiable information
          from children under 13. In the case I discover that a child
          under 13 has provided me with personal information, I
          immediately delete this from our servers. If you are a
          parent or guardian and you are aware that your child has
          provided us with personal information, please contact me so
          that I will be able to do necessary actions.
        </Typography>
      </Box>
      <Box px={5} py={1}>
        <Typography variant='h6'>
          Changes to This Privacy Policy
        </Typography>
      </Box>
      <Box px={5} py={1}>
        <Typography variant='body2'>
          I may update our Privacy Policy from time to time. Thus, you
          are advised to review this page periodically for any
          changes. I will notify you of any changes by posting the new
          Privacy Policy on this page. This policy is effective as of
          2021-02-22.
        </Typography>
      </Box>
      <Box px={5} py={1}>
        <Typography variant='h6'>Contact Us</Typography>
      </Box>
      <Box px={5} py={1}>
        <Typography variant='body2'>
          If you have any questions or suggestions about my Privacy
          Policy, do not hesitate to contact me at
          stockoptioncalculator@gmail.com.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
