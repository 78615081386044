import {
  createMuiTheme,
  responsiveFontSizes,
} from '@material-ui/core/styles';

import green from '@material-ui/core/colors/green';

// const primary = green[500];

export const socTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: green[500],
      dark: green[800],
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
    error: {
      main: '#ff0000',
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      'Karla',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MuiTypography: {
      body1: {
        fontWeight: 500,
        // paddingTop: '2%',
        // paddingBottom: '2%',
      },
      body2: {
        fontWeight: 400,
        fontSize: '1rem',
        // padding: '2%',
      },
      h1: {
        fontWeight: 500,
        textAlign: 'center',
        fontSize: '2.2rem',
        // marginBottom: '2%',
        paddingTop: '3%',
        paddingBottom: '3%',
        // '@media (max-width:600px)': {
        //   fontSize: '2.1rem',
        // },
        letterSpacing: 1.3
        // color: green[700],
        // textShadow: '0 2px 0 #bdbdbd'
      },
      h5: {
        fontWeight: 500,
        padding: '1% 0',
        textTransform: 'uppercase',
      },
      h6: {
        padding: '1% 0',
      },
    },
    MuiButton: {
      // text: {
      //   boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      //   // margin: '3%'
      // },
      root: {
        width: '90%',
        margin: '4% auto',
      },
    },
    MuiTextField: {
      root: {
        width: '90%',
        margin: '1% auto',
      },
    },
    MuiIcon: {
      root: {
        color: 'primary',
      },
    },
    MuiToggleButton: {
      root: {
        backgroundColor: 'primary',
      },
    },
    MuiGrid: {
      root: {
        padding: '0 2.5%',
        // '@media (max-width:600px)':{
        //   padding: 0
        // }
      },
    },
    MuiFormHelperText: {
      root: {
        color: 'red',
      },
    },
    MuiContainer: {
      root: {
        minHeight: '100vh',
        paddingBottom: '30px',
        '@media (max-width:600px)': {
          minHeight: '80vh'
        }

      }
    },
    MuiPaper: {
      rounded: {
        borderRadius: '20px'
      }
    }
  },
});

responsiveFontSizes(socTheme);
