import React from 'react';

import { Typography, Box, Container } from '@material-ui/core';

const TermsAndConditions = () => {
  return (
    <Container id='terms'>
      <Box p={5}>
        <Typography variant='h5'>Terms and Conditions</Typography>
        <Box pb={1}>
          <Typography variant='body1'>
            By accessing this website/app, you agree to the Terms of
            Service as outlined below.{' '}
          </Typography>
        </Box>
        <Box fontStyle='italic'>
          <Typography variant='body2'>
            This website/app does not give investment or financial
            advice. The content is for informational purposes only.
            The information and calculations provided on this
            website/app are provided as an estimate only and we do not
            guarantee accuracy, reliability, and completeness. All
            information and calculations are hypothetical and are for
            illustrative purposes. Any action you take based on the
            information you find on this website/app is strictly at
            your own risk. We are not liable for any losses and/or
            damages in connection with the use of this website/app.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default TermsAndConditions;
